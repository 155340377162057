
import { defineComponent, onMounted, reactive, watch } from "vue";
import { getFacility, updateFacility } from "@/core/services/api/facilities";
import localstorage from "@/core/services/LocalstorageService";
import draggable from "vuedraggable";
import { getFilePath } from "@/core/utils/file";
import PreviewImg from "./PreviewImg.vue";
import { Modal } from "bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { deleteFacilityImages } from "@/core/services/api/file";

export default defineComponent({
  components: {
    draggable,
    PreviewImg,
  },
  props: ["facilityGuid"],
  emits: ["cancelPreview", "uploadFile"],
  setup(props) {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    let previewModal;
    let previewFile: any = reactive([]);
    const facilityThemeImgs: any = reactive([]);
    const facilityData = reactive({
      type: "Facility",
      mode: "Standard",
      status: "Public",
      information: [
        {
          icon: "string",
          title: "string",
          content: "string",
        },
      ],
      theme: {
        coverPhoto: "string",
        images: ["string"],
        locationImages: ["string"],
      },
      setting: {
        availableDays: 0,
        invitationNumber: 0,
        minuteToEnter: 0,
        minuteToCancel: 0,
        minuteToExtend: 0,
        minuteToCheckOut: 0,
        autoRelease: true,
        delayCaptureInDays: 0,
        canDoorAccess: true,
        canIotFetch: true,
        canDistribute: true,
        canInvite: true,
        canUserCheckOut: true,
        canUserFeedback: true,
      },
      guid: "string",
      space: "string",
      id: "string",
      name: "string",
      category: "string",
      createTime: "2021-11-10T01:35:14.477Z",
      description: "string",
      openingHoursDescription: "string",
      location: "string",
      quota: 0,
      displayOrder: 0,
      usageNotice: "string",
      priceTag: "string",
    });

    const setfacilityThemeImgs = () => {
      let themeImgs: any = facilityData.theme.images;
      facilityThemeImgs.splice(0, facilityThemeImgs.length, ...themeImgs);
    };

    const getImgPath = (img) => {
      return getFilePath(img);
    };

    const remove = async (file) => {
      await Swal.fire({
        title: "您確定要刪除圖片嗎?",
        confirmButtonText: "確定",
        denyButtonText: `取消`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteFacilityImages(
            currentSpaceGuid!,
            props.facilityGuid,
            file
          ).then(() => {
            Swal.fire("刪除成功!", "", "success").then(() => {
              init();
            });
          });
        }
      });
    };

    const cancelPreview = () => {
      previewFile.splice(0);
      let obj: any = document.getElementById(
        "facilityManagement-inputGroupFile"
      );
      obj.value = "";
      previewModal.hide();
    };

    const filesIsEmpty = () => {
      return previewFile.length == 0;
    };

    const viewData = (e) => {
      previewFile.splice(0, previewFile.length, ...e.target.files);
      if (!filesIsEmpty()) {
        previewModal.show();
      }
    };

    const setFacilityData = async () => {
      let response: any = await getFacility(props.facilityGuid);
      Object.assign(facilityData, response.facility);
    };

    const storageSort = async () => {
      let updateFacilityRequest = {
        guid: props.facilityGuid,
        name: facilityData.name,
        category: facilityData.category,
        mode: facilityData.mode,
        status: facilityData.status,
        description: facilityData.description,
        openingHoursDescription: facilityData.openingHoursDescription,
        location: facilityData.location,
        images: facilityThemeImgs,
        information: facilityData.information,
        setting: facilityData.setting,
        displayOrder: facilityData.displayOrder,
        usageNotice: facilityData.usageNotice,
        priceTag: facilityData.priceTag,
      };
      await updateFacility(updateFacilityRequest).then(() => {
        Swal.fire("排序已更新!", "", "success").then(() => {
          init();
        });
      });
    };

    onMounted(() => {
      previewModal = new Modal(
        document.getElementById("facility-ThemePhoto-preview-modal")
      );
    });

    const init = async () => {
      await setFacilityData();
      setfacilityThemeImgs();
    };
    init();

    return {
      facilityThemeImgs,
      getImgPath,
      remove,
      viewData,
      storageSort,
      previewFile,
      cancelPreview,
      props,
      init,
    };
  },
});
