import ApiService from "@/core/services/ApiService";
import { FacilityExtend } from "@/core/services/responsesModel/facilityExtend"
import { FacilityTimeRanges } from "@/core/services/responsesModel/facilityTimeRanges"

export const statusDictionary = {
    'Public': '開放',
    'Private': '關閉 ',
    "Protected": "開放(不可預約)"
}
export const typeDictionary = {
    'Facility': '設施',
    'Property': '設備'
}
export const modeDictionary = {
    'Standard': '標準',
    'PrivateOffice': '私人辦公室',
    'Overlap': '彈性配置'
}
export const overlapTypeDictionary = {
    'Overlap': '重疊',
    'Whole': '包場'
}

// 取得所有Facility資料
export function getFacilityBySpace(spaceGuid: string): Promise<Array<FacilityExtend>> {
    return new Promise((resolve, reject) => {
        ApiService.get(`Spaces/${spaceGuid}/Facility`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 新增Facility資料
export function addFacility(request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Facilities/Add`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 取得Facility資料
export function getFacility(facilityGuid) {
    return new Promise((resolve, reject) => {
        ApiService.get(`Facilities/${facilityGuid}`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 更新Facility資料
export function updateFacility(request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Facilities/UpdateData`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 
export function getTimeRangeReserve(request): Promise<Array<FacilityTimeRanges>> {
    return new Promise((resolve, reject) => {
        ApiService.post(`facilities/QueryTimeRange`, request).then(res => {
            resolve(res.data.facilitiesTimeRanges)
        }).catch(err => {
            reject(err)
        })
    })
}