<template>
  <div class="row mb-10">
    <div class="col">
      <div class="input-group">
        <input
          multiple
          type="file"
          class="form-control"
          id="facilityManagement-inputGroupFile"
          accept="image/jpeg"
          aria-describedby="facilityManagement-inputGroupFile"
          aria-label="Upload"
          @change="viewData($event)"
        />
      </div>
      <div class="mt-2" style="color: red; font-size: 16px">
        ※上傳圖片解析請於 1920 x 1080 以內。
      </div>
      <div class="mt-2" style="color: red; font-size: 16px">
        ※拖動下方圖片以改變順序，排序第一為封面照。
      </div>
    </div>
    <div class="col d-flex justify-content-end align-items-start">
      <button
        type="button"
        @click="storageSort()"
        class="btn btn-primary"
        style="height: 42.5px"
      >
        儲存排序
      </button>
    </div>
  </div>
  <div class="mb-10">
    <draggable
      class="p-0"
      style="width: 100%"
      tag="transition-group"
      :component-data="{
        tag: 'ul',
        type: 'transition-group',
        name: !drag ? 'flip-list' : null,
      }"
      :list="facilityThemeImgs"
      v-bind="dragOptions"
      @start="drag = true"
      @end="drag = false"
      item-key="order"
    >
      <template #item="{ element, index }">
        <div class="draggableImg me-10">
          <i class="fs-4 ms-1">{{ index + 1 }}</i>
          <div
            class="
              btn btn-sm btn-icon btn-color-danger btn-active-color-danger
              iconX
            "
            @click="remove(element)"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <img
            style="width: 225px; height: 168px"
            :src="getImgPath(element)"
            class="img-fluid img-thumbnail"
            alt="..."
          />
        </div>
      </template>
    </draggable>
  </div>
  <div class="row">
    <div class="col-12">
      <div
        id="facilityManagement-carouselExampleIndicators"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <template v-for="(img, index) in facilityThemeImgs" :key="img">
            <div class="carousel-item" :class="{ active: index == 0 }">
              <img :src="getImgPath(img)" class="d-block w-100" alt="..." />
            </div>
          </template>

          <!-- <div class="carousel-item">
          <img src="..." class="d-block w-100" alt="..." />
        </div>
        <div class="carousel-item">
          <img src="..." class="d-block w-100" alt="..." />
        </div> -->
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#facilityManagement-carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">上一張</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#facilityManagement-carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">下一張</span>
        </button>
      </div>
    </div>
  </div>
  <!-- 預覽照片modal -->
  <div
    class="modal fade"
    id="facility-ThemePhoto-preview-modal"
    data-bs-backdrop="static"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="facility-ThemePhoto-exampleModalLabel">
            預覽照片
          </h5>
          <!-- <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button> -->
        </div>
        <div class="modal-body">
          <PreviewImg
            :facilityGuid="props.facilityGuid"
            :previewFile="previewFile"
            @cancelPreview="cancelPreview()"
            @uploadFile="init()"
          ></PreviewImg>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, watch } from "vue";
import { getFacility, updateFacility } from "@/core/services/api/facilities";
import localstorage from "@/core/services/LocalstorageService";
import draggable from "vuedraggable";
import { getFilePath } from "@/core/utils/file";
import PreviewImg from "./PreviewImg.vue";
import { Modal } from "bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { deleteFacilityImages } from "@/core/services/api/file";

export default defineComponent({
  components: {
    draggable,
    PreviewImg,
  },
  props: ["facilityGuid"],
  emits: ["cancelPreview", "uploadFile"],
  setup(props) {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    let previewModal;
    let previewFile: any = reactive([]);
    const facilityThemeImgs: any = reactive([]);
    const facilityData = reactive({
      type: "Facility",
      mode: "Standard",
      status: "Public",
      information: [
        {
          icon: "string",
          title: "string",
          content: "string",
        },
      ],
      theme: {
        coverPhoto: "string",
        images: ["string"],
        locationImages: ["string"],
      },
      setting: {
        availableDays: 0,
        invitationNumber: 0,
        minuteToEnter: 0,
        minuteToCancel: 0,
        minuteToExtend: 0,
        minuteToCheckOut: 0,
        autoRelease: true,
        delayCaptureInDays: 0,
        canDoorAccess: true,
        canIotFetch: true,
        canDistribute: true,
        canInvite: true,
        canUserCheckOut: true,
        canUserFeedback: true,
      },
      guid: "string",
      space: "string",
      id: "string",
      name: "string",
      category: "string",
      createTime: "2021-11-10T01:35:14.477Z",
      description: "string",
      openingHoursDescription: "string",
      location: "string",
      quota: 0,
      displayOrder: 0,
      usageNotice: "string",
      priceTag: "string",
    });

    const setfacilityThemeImgs = () => {
      let themeImgs: any = facilityData.theme.images;
      facilityThemeImgs.splice(0, facilityThemeImgs.length, ...themeImgs);
    };

    const getImgPath = (img) => {
      return getFilePath(img);
    };

    const remove = async (file) => {
      await Swal.fire({
        title: "您確定要刪除圖片嗎?",
        confirmButtonText: "確定",
        denyButtonText: `取消`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteFacilityImages(
            currentSpaceGuid!,
            props.facilityGuid,
            file
          ).then(() => {
            Swal.fire("刪除成功!", "", "success").then(() => {
              init();
            });
          });
        }
      });
    };

    const cancelPreview = () => {
      previewFile.splice(0);
      let obj: any = document.getElementById(
        "facilityManagement-inputGroupFile"
      );
      obj.value = "";
      previewModal.hide();
    };

    const filesIsEmpty = () => {
      return previewFile.length == 0;
    };

    const viewData = (e) => {
      previewFile.splice(0, previewFile.length, ...e.target.files);
      if (!filesIsEmpty()) {
        previewModal.show();
      }
    };

    const setFacilityData = async () => {
      let response: any = await getFacility(props.facilityGuid);
      Object.assign(facilityData, response.facility);
    };

    const storageSort = async () => {
      let updateFacilityRequest = {
        guid: props.facilityGuid,
        name: facilityData.name,
        category: facilityData.category,
        mode: facilityData.mode,
        status: facilityData.status,
        description: facilityData.description,
        openingHoursDescription: facilityData.openingHoursDescription,
        location: facilityData.location,
        images: facilityThemeImgs,
        information: facilityData.information,
        setting: facilityData.setting,
        displayOrder: facilityData.displayOrder,
        usageNotice: facilityData.usageNotice,
        priceTag: facilityData.priceTag,
      };
      await updateFacility(updateFacilityRequest).then(() => {
        Swal.fire("排序已更新!", "", "success").then(() => {
          init();
        });
      });
    };

    onMounted(() => {
      previewModal = new Modal(
        document.getElementById("facility-ThemePhoto-preview-modal")
      );
    });

    const init = async () => {
      await setFacilityData();
      setfacilityThemeImgs();
    };
    init();

    return {
      facilityThemeImgs,
      getImgPath,
      remove,
      viewData,
      storageSort,
      previewFile,
      cancelPreview,
      props,
      init,
    };
  },
});
</script>


<style scoped>
.draggableImg {
  width: 225px;
  height: 190px;
  display: inline-block;
  position: relative;
}

.iconX {
  display: none;
  position: absolute;
  top: 25px;
  left: 184px;
}

.draggableImg:hover .iconX {
  display: block;
}
</style>