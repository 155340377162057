import ApiService from "@/core/services/ApiService";

// 移除空間主題照片
export function deleteSpaceImages(spaceGuid: string, fileGuid: string) {
    return new Promise((resolve, reject) => {
        ApiService.delete(`Space/${spaceGuid}/Theme/Images/File/${fileGuid}`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 上傳空間主題照片
export function uploadSpaceImages(spaceGuid: string, request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/Theme/Images/File`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}


// 移除空間平面圖照片
export function deleteSpaceLocationImages(spaceGuid: string, fileGuid: string) {
    return new Promise((resolve, reject) => {
        ApiService.delete(`Space/${spaceGuid}/Theme/LocationImages/File/${fileGuid}`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 上傳空間平面圖照片
export function uploadSpaceLocationImages(spaceGuid: string, request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/Theme/LocationImages/File`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 上傳設施主題照片
export function uploadFacilityImages(spaceGuid: string, facilityGuid: string, request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/Facility/${facilityGuid}/Theme/Images/File`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 移除設施主題照片
export function deleteFacilityImages(spaceGuid: string, facilityGuid: string, fileGuid: string) {
    return new Promise((resolve, reject) => {
        ApiService.delete(`Space/${spaceGuid}/Facility/${facilityGuid}/Theme/Images/File/${fileGuid}`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 上傳設施平面圖照片
export function uploadFacilityLocationImages(spaceGuid: string, facilityGuid: string, request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/Facility/${facilityGuid}/Theme/LocationImages/File`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 移除設施平面圖照片
export function deleteFacilityLocationImages(spaceGuid: string, facilityGuid: string, fileGuid: string) {
    return new Promise((resolve, reject) => {
        ApiService.delete(`Space/${spaceGuid}/Facility/${facilityGuid}Theme/LocationImages/File/${fileGuid}`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 上傳公告封面照
export function uploadAnnouncementsCoverPhoto(announcementGuid, file) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Announcement/${announcementGuid}/File/CoverPhoto`, file).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 上傳公告檔案
export function uploadAnnouncementsFile(announcementGuid, file) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Announcement/${announcementGuid}/File`, file).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 移除公告檔案
export function deleteAnnouncementsFile(announcementGuid: string, fileGuid: string) {
    return new Promise((resolve, reject) => {
        ApiService.delete(`Announcement/${announcementGuid}/File/${fileGuid}`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}
