
import { defineComponent } from "vue";
import ThemePhoto from "./components/ThemePhoto.vue";
import FloorPlan from "./components/FloorPlan.vue";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  components: {
    ThemePhoto,
    FloorPlan
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const facilityGuid = route.params.facilityGuid;
    return {
        facilityGuid
    }
  },
});
