
import { defineComponent, onMounted } from "vue";
import { uploadFacilityImages } from "@/core/services/api/file";
import localstorage from "@/core/services/LocalstorageService";
import { useRouter } from "vue-router";
import { Modal } from "bootstrap";

export default defineComponent({
  props: ["facilityGuid", "previewFile"],
  setup(props, { emit }) {
    // let previewModal;
    const router = useRouter();
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const getFileUrl = (file) => {
      if (file !== null && file !== undefined) {
        const fileUrl = URL.createObjectURL(file);
        return fileUrl;
      }
    };

    const uploadFile = async () => {
      let filesLength = props.previewFile.length;
      for (let index = 0; index < props.previewFile.length; index++) {
        let formData = new FormData();
        formData.set(
          "file",
          props.previewFile[index],
          props.previewFile[index].name
        );
        await uploadFacilityImages(
          currentSpaceGuid!,
          props.facilityGuid,
          formData
        ).then((res: any) => {
          if (res.result) {
            if (filesLength == index + 1) {
          
              emit('uploadFile')
              cancel()
            }
          }
        });
      }
    };

    const cancel = () => {
      // previewModal.hide();
      emit("cancelPreview");
    };

    // onMounted(() => {
    //   previewModal = new Modal(
    //     document.getElementById("facility-ThemePhoto-preview-modal")
    //   );
    // });
    return {
      getFileUrl,
      props,
      uploadFile,
      cancel,
    };
  },
});
