<template>
  <div class="row mb-10">
    <div class="col-6">
      <div class="input-group">
        <input
          multiple
          type="file"
          class="form-control"
          id="facilityManagement-inputGroupFileFloorPlan"
          accept="image/jpeg"
          aria-describedby="inputGroupFileAddon04"
          aria-label="Upload"
          @change="viewData($event)"
        />
      </div>
      <div class="mt-2" style="color: red; font-size: 16px">
        ※上傳圖片解析請於 1920 x 1080 以內。
      </div>
      <div class="mt-2" style="color: red; font-size: 16px">
        ※拖動下方圖片以改變順序，排序第一為封面照。
      </div>
    </div>
  </div>

  <div class="mb-10">
    <draggable
      class="p-0"
      style="width: 100%"
      tag="transition-group"
      :component-data="{
        tag: 'ul',
        type: 'transition-group',
        name: !drag ? 'flip-list' : null,
      }"
      :list="facilityThemeImgs"
      v-bind="dragOptions"
      @start="drag = true"
      @end="drag = false"
      item-key="order"
    >
      <template #item="{ element, index }">
        <div class="draggableImg me-10">
          <i class="fs-4 ms-1">{{ index + 1 }}</i>
          <div
            class="
              btn btn-sm btn-icon btn-color-danger btn-active-color-danger
              iconX
            "
            @click="remove(element)"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <img
            style="width: 225px; height: 168px"
            :src="getImgPath(element)"
            class="img-fluid img-thumbnail"
            alt="..."
          />
        </div>
      </template>
    </draggable>
  </div>
  <div class="row">
    <div class="col-12">
      <div
        id="facilityManagement-floorPlan-carousel"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <template v-for="(img, index) in facilityThemeImgs" :key="img">
            <div class="carousel-item" :class="{ active: index == 0 }">
              <img :src="getImgPath(img)" class="d-block w-100" alt="..." />
            </div>
          </template>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#facilityManagement-floorPlan-carousel"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">上一張</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#facilityManagement-floorPlan-carousel"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">下一張</span>
        </button>
      </div>
    </div>
  </div>
  <!-- 預覽照片modal -->
  <div
    class="modal fade"
    id="facilityManagement-floorPlan-previewLocationImg-modal"
    data-bs-backdrop="static"
    tabindex="-1"
    aria-labelledby="exampleModalLabelLocationImg"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
            id="facilityManagement-floorPlan-exampleModalLabelLocationImg"
          >
            預覽照片
          </h5>
        </div>
        <div class="modal-body">
          <PreviewLocationImg
            :facilityGuid="props.facilityGuid"
            :previewFile="previewFile"
            @cancelPreview="cancelPreview()"
            @uploadFile="init()"
          ></PreviewLocationImg>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { computed, defineComponent, onMounted, reactive } from "vue";
import localstorage from "@/core/services/LocalstorageService";
import draggable from "vuedraggable";
import { getFilePath } from "@/core/utils/file";
import { deleteFacilityLocationImages } from "@/core/services/api/file";
import Swal from "sweetalert2/dist/sweetalert2.js";
import PreviewLocationImg from "./PreviewLocationImg.vue";
import { Modal } from "bootstrap";
import { getSpacesByManager } from "@/core/services/api/spaces";
import { useRouter } from "vue-router";
import { updateSpace } from "@/core/services/api/spaces";
import { getFacility, updateFacility } from "@/core/services/api/facilities";

export default defineComponent({
  components: {
    draggable,
    PreviewLocationImg,
  },
  emits: ["cancelPreview", "uploadFile"],
  props: ["facilityGuid"],
  setup(props) {
    const facilityData = reactive({
      type: "Facility",
      mode: "Standard",
      status: "Public",
      information: [
        {
          icon: "string",
          title: "string",
          content: "string",
        },
      ],
      theme: {
        coverPhoto: "string",
        images: ["string"],
        locationImages: ["string"],
      },
      setting: {
        availableDays: 0,
        invitationNumber: 0,
        minuteToEnter: 0,
        minuteToCancel: 0,
        minuteToExtend: 0,
        minuteToCheckOut: 0,
        autoRelease: true,
        delayCaptureInDays: 0,
        canDoorAccess: true,
        canIotFetch: true,
        canDistribute: true,
        canInvite: true,
        canUserCheckOut: true,
        canUserFeedback: true,
      },
      guid: "string",
      space: "string",
      id: "string",
      name: "string",
      category: "string",
      createTime: "2021-11-10T01:35:14.477Z",
      description: "string",
      openingHoursDescription: "string",
      location: "string",
      quota: 0,
      displayOrder: 0,
      usageNotice: "string",
      priceTag: "string",
    });
    const currentSpace = localstorage.getCurrentSpaces()?.space;
    const router = useRouter();
    let previewFile: any = reactive([]);
    let previewModal1;
    let files = reactive([]);
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const facilityThemeImgs: any = reactive([]);
    const setFacilityData = async () => {
      let response: any = await getFacility(props.facilityGuid);
      Object.assign(facilityData, response.facility);
    };
    const setfacilityThemeImgs = () => {
      let themeImgs: any = facilityData.theme.locationImages;
      facilityThemeImgs.splice(0, facilityThemeImgs.length, ...themeImgs);
    };

    const dragOptions = computed(() => {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    });

    const getImgPath = (img) => {
      return getFilePath(img);
    };

    const remove = async (file) => {
      await Swal.fire({
        title: "您確定要刪除圖片嗎?",
        confirmButtonText: "確定",
        denyButtonText: `取消`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteFacilityLocationImages(
            currentSpaceGuid!,
            props.facilityGuid,
            file
          ).then(() => {
            Swal.fire("刪除成功!", "", "success").then(() => {
              init();
            });
          });
        }
      });
    };

    const viewData = (e) => {
      previewFile.splice(0, previewFile.length, ...e.target.files);
      if (!filesIsEmpty()) {
        previewModal1.show();
      }
    };

    const cancelPreview = () => {
      previewFile.splice(0);
      let obj: any = document.getElementById(
        "facilityManagement-inputGroupFileFloorPlan"
      );
      obj.value = "";
      previewModal1.hide();
    };

    onMounted(() => {
      previewModal1 = new Modal(
        document.getElementById(
          "facilityManagement-floorPlan-previewLocationImg-modal"
        )
        
      );
    });

    const filesIsEmpty = () => {
      return previewFile.length == 0;
    };

    const init = async () => {
      await setFacilityData();
      setfacilityThemeImgs();
    };
    init();

    return {
      dragOptions,
      getImgPath,
      remove,
      viewData,
      files,
      previewFile,
      cancelPreview,
      filesIsEmpty,
      facilityThemeImgs,
      props,
      init,
    };
  },
});
</script>

<style scoped>
.draggableImg {
  width: 225px;
  height: 190px;
  display: inline-block;
  position: relative;
}

.iconX {
  display: none;
  position: absolute;
  top: 25px;
  left: 184px;
}

.draggableImg:hover .iconX {
  display: block;
}
</style>
